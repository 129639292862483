<template>
  <v-theme-provider>
    <base-section
      id="company-overview"
    >
      <v-container>
        <v-img
          :src="require('@/assets/howellcare_logo_new_footer.png')"
          contain
          max-height="100"
        >
        </v-img>
        <br>
        <base-section-heading
          class="align-self-center mx-auto"
          title="company overview"
        />
        <p>
        &nbsp;&nbsp;Howellcare Industries, established in 2019 in Teluk Intan, Perak, is a fast-growing company specializing in high-quality glove trading. Led by Mr. Francis Ho, our aim is to be a leading player by offering top-notch gloves for various industries. <br><br>&nbsp;&nbsp;Our products include disposable exam gloves (for healthcare, beautycare and food handling) and industrial gloves (for chemical handling and automotive sector). <br><br>&nbsp;&nbsp;With over 20 years of experience, we provide professional advice on technology transition, factory development, and machine installation related to glove industries. Howellcare takes immense pride in our ability to venture our export to international markets (Australia, Europe, Korea, and USA). We emphasize corporate social responsibility through support for the elderly, aiding the less fortunate, education subsidies and most importantly aligning with ESG for ethical business practices. <br><br>&nbsp;&nbsp;Our focus is to deliver quality and building long-lasting relationships in the global glove's market.
      </p>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionCompanyOverview',
  }
</script>
